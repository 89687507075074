.container{
    background-color: #f7f8f9;
}
.gridContainer{
    background-color: white;
}
body{
    background-color: #f7f8f9;
}
h1, h2, .MuiTypography-h5{
    color:#008537
}

header{
    background-color: white;
}
.inside-header{
    display: flex;
    align-items: center;
    padding:20px
}
.site-logo{
    display: inline-block;
    max-width: 100%;
}
.site-header .header-image{
    width: 200px;
    vertical-align: middle;
}
.site-header{
    height: 140px;
}
img{
    height: auto;
    margin-left: 20px;
    max-width: 100%;
}
.h2solicitudes{
    margin-top:20px;
    margin-left: 20px;
}